<template>
    <div>

        <SketchUpComponentDialog v-if="showSketchUpComponentDialog" v-bind="sketchUpComponentDialogParameters"
                                 v-on:hidden="showSketchUpComponentDialog = false"
                                 v-on:select-component="selectComponent"
                                 v-on:zoom-component="zoomComponent"
                                 v-on:zoom-reset="zoomReset"/>

        <div class="card st-framework-card">

            <div class="card-header">
                <table style="border-collapse: collapse; width: 100%;">
                    <tr>
                        <td style="margin: 0; padding: 0 0 0 1rem; width: 50px;">
                            <font-awesome-icon :icon="icon" size="sm"/>
                        </td>
                        <td style="text-align: center;">
                            {{ $i18n.tc('translations.' + name, 2) + " (" + (project ? project.label : $i18n.t('translations.No Project Found')) + ")" }}
                        </td>
                        <td style="width: 50px;"/>
                    </tr>
                </table>
            </div>

            <div class="card-body p-0" v-if="pageLoaded">
                <Table :key="'component-table-'+tableReload" header-class="light" footer-class="light" body-class=""
                       with-filter with-pagination no-state-loading autofocus :lines-per-page=35
                       :reload-table-data="reloadTableData" :rows="filteredComponentList"
                       :fields="fields.tableFields" :actions="actions.tableActions" :state="state"
                       v-on:clear-selection="clearSelection"
                       v-on:detail-components="detailComponents"
                       v-on:inline-edit="toggleComponentCheck"
                       v-on:make-unique="makeComponentsUnique"
                       v-on:refresh="getComponentInstances"
                       v-on:renumber-definitions="renumberAssemblyDefinitions"
                       v-on:select-all="selectAll"
                       v-on:select-components="selectComponent"
                       v-on:select-filter-options="selectFilterOptions"
                       v-on:zoom-component="zoomComponent"
                       v-on:zoom-reset="zoomReset"/>
            </div>

        </div>
    </div>
</template>

<script>
import SketchUpComponentDialog from "@/components/dialogs/SketchUpComponentDialog.vue";
import Table from "@/components/Table.vue";

/* global sketchup:false */

export default {
    name: 'Components',
    components: {
        SketchUpComponentDialog,
        Table,
    },
    props: ['name', 'icon', 'api', 'state'],
    data() {
        return {
            sketchUpToolData: null,
            pageLoaded: false,
            project: null,
            fields: null,
            actions: null,
            products: null,
            settings: null,
            processedComponentList: [],
            filteredComponentList: [],
            checkedComponentList: [],
            tableReload: 0,
            reloadTableData: 0,
            filterOptions: {
                productsOnly: false,
                assemblyOnly: false,
                nonuniqueOnly: false,
                uncheckedOnly: false,
            },
            showSketchUpComponentDialog: false,
            sketchUpComponentDialogParameters: {
                title: null,
                actions: null,
                fields: null,
                rows: null,
            },
        }
    },
    created() {
        this.state.loading = true;
        window.vm.Tool = this;
        sketchup.getToolData();
    },
    methods: {
        clearSelection() {
            sketchup.clearSelection();
        },
        detailComponents(component) {
            let rows = [];
            component.instances.forEach(entityID => {
                let instanceIdx = rows.findIndex(row => row.entity_id === entityID);
                if (instanceIdx === -1) {
                    rows.push({
                        id: rows.length + 1,
                        component_color: component.component_color,
                        entity_id: entityID,
                        instances: 1,
                        flipped: component.flipped,
                    });
                } else {
                    rows[instanceIdx].instances++;
                }
            });
            this.sketchUpComponentDialogParameters.title = component.definition;
            this.sketchUpComponentDialogParameters.actions = this.actions.detailActions;
            this.sketchUpComponentDialogParameters.fields = this.fields.detailFields;
            this.sketchUpComponentDialogParameters.rows = rows;
            this.showSketchUpComponentDialog = true;
        },
        fetchData() {
            this.$http.get(this.api + '/manage_components/' + this.sketchUpToolData.project_id, {}).then((res) => {
                this.project = res.data.project;
                this.fields = res.data.fields;
                this.actions = res.data.actions;
                this.products = res.data.products;
                this.settings = res.data.settings;
                sketchup.getComponentInstances();
            }).catch((error) => {
                console.log("Components:fetchData():error:", error);
            });
        },
        filterComponentList() {
            this.filteredComponentList = this.processedComponentList.filter(component =>
                (!this.filterOptions.nonuniqueOnly || component.hide_make_unique === false) &&
                (!this.filterOptions.productsOnly || component.attribute_naam !== null) &&
                (!this.filterOptions.assemblyOnly || this.settings.assembly_definitions.includes(component.definition.substring(0, component.definition.lastIndexOf("#")))) &&
                (!this.filterOptions.uncheckedOnly || component.checked === 0)
            );
        },
        getComponentInstances() {
            this.state.loading = true;
            sketchup.getComponentInstances();
        },
        getComponentInstancesCallback(entities) {
            this.$worker.run((rawComponentList, products, checkedComponentList) => {
                let newProcessedComponentList = [];

                rawComponentList.forEach(component => {
                    let i = newProcessedComponentList.findIndex(item =>
                        (item.definition === component.definition) &&
                        (item.attribute_naam === component.attribute_naam) &&
                        (item.attribute_hoeveelheid === component.attribute_hoeveelheid) &&
                        (item.is_flipped === component.is_flipped)
                    );
                    if (i === -1) {
                        let j = newProcessedComponentList.findIndex(item =>
                            (item.definition === component.definition) &&
                            (item.attribute_naam === component.attribute_naam) &&
                            (item.attribute_hoeveelheid === component.attribute_hoeveelheid) &&
                            (item.is_flipped === (component.is_flipped === 1 ? 0 : 1))
                        );
                        let newComponent = {
                            id: newProcessedComponentList.length + 1,
                            checked: 0,
                            entity_ids: [component.entity_id],
                            instances: [component.entity_id],
                            definition: component.definition ? component.definition : '',
                            instances_in_model: component.instances_in_model,
                            component_color: {
                                value: component.component_color,
                                label: component.component_color_name,
                            },
                            attribute_naam: component.attribute_naam,
                            attribute_hoeveelheid: component.attribute_hoeveelheid,
                            attribute_eenheid: component.attribute_eenheid,
                            is_flipped: component.is_flipped,
                            known_in_tool: (products.indexOf(component.attribute_naam) > -1 ? 'X' : ''),
                            flipped: (component.is_flipped ? 'X' : ''),
                            components_in_selection: 1,
                            instances_in_selection: 1,
                            amount: (component.attribute_hoeveelheid > 0 ? component.attribute_hoeveelheid : ''),
                            hide_make_unique: true,
                        }
                        let checked = checkedComponentList.findIndex(item => (
                            (item.definition === newComponent.definition) &&
                            (item.flipped === newComponent.flipped))
                        );
                        if (checked !== -1) {
                            newComponent.checked = 1;
                            newComponent.checked_style = 'background: #28a745; color: white;';
                        }
                        if (j === -1) {
                            newComponent.hide_make_unique = true;
                        } else {
                            newProcessedComponentList[j].hide_make_unique = false;
                            if (newProcessedComponentList[j].is_flipped === 1) {
                                newProcessedComponentList[j].flipped_style = 'background: red; color: white;';
                            }
                            newComponent.hide_make_unique = false;
                            if (newComponent.is_flipped === 1) {
                                newComponent.flipped_style = 'background: red; color: white;';
                            }
                        }
                        newProcessedComponentList.push(newComponent);
                    } else {
                        if (newProcessedComponentList[i].entity_ids.indexOf(component.entity_id) === -1) {
                            newProcessedComponentList[i].entity_ids.push(component.entity_id);
                            newProcessedComponentList[i].components_in_selection++;
                        }
                        newProcessedComponentList[i].instances.push(component.entity_id);
                        newProcessedComponentList[i].instances_in_selection++;
                        if (component.attribute_hoeveelheid > 0) {
                            newProcessedComponentList[i].amount += component.attribute_hoeveelheid;
                        }
                    }
                });

                newProcessedComponentList.sort(function (a, b) {
                    let idxA = a.definition.lastIndexOf('#');
                    let idxB = b.definition.lastIndexOf('#');
                    if (idxA > -1 && idxB > -1 && a.definition.substring(0, idxA) === b.definition.substring(0, idxB)) {
                        if (parseInt(a.definition.substring(idxA + 1)) < parseInt(b.definition.substring(idxB + 1))) return -1;
                        if (parseInt(a.definition.substring(idxA + 1)) > parseInt(b.definition.substring(idxB + 1))) return 1;
                    } else {
                        if (a.definition < b.definition) return -1;
                        if (a.definition > b.definition) return 1;
                    }
                    if (a.attribute_naam < b.attribute_naam) return -1;
                    if (a.attribute_naam > b.attribute_naam) return 1;
                    if (a.attribute_hoeveelheid < b.attribute_hoeveelheid) return -1;
                    if (a.attribute_hoeveelheid > b.attribute_hoeveelheid) return 1;
                    if (a.is_flipped < b.is_flipped) return -1;
                    if (a.is_flipped > b.is_flipped) return 1;
                    return 0;
                });

                return newProcessedComponentList;
            }, [JSON.parse(entities), this.products, this.checkedComponentList]).then(data => {
                this.processedComponentList = data;
                this.filterComponentList();
                this.tableReload++;
                this.pageLoaded = true;
                this.state.loading = false;
            }).catch(error => {
                console.log("Components:getComponentInstancesCallback():error:", error);
            });
        },
        getModelDefinitionsCallback(definitions) {
            definitions.sort(function (a, b) {
                let idxA = a.lastIndexOf('#');
                let idxB = b.lastIndexOf('#');
                if (idxA > -1 && idxB > -1 && a.substring(0, idxA) === b.substring(0, idxB)) {
                    if (parseInt(a.substring(idxA + 1)) < parseInt(b.substring(idxB + 1))) return -1;
                    if (parseInt(a.substring(idxA + 1)) > parseInt(b.substring(idxB + 1))) return 1;
                } else {
                    if (a < b) return -1;
                    if (a > b) return 1;
                }
                return 0;
            });

            let expectedNumber;
            let gapsInDefinition = [];
            let definitionsToRenumber = [];
            let previousDefinition = null;
            let currentDefinitionName = null;
            definitions.forEach(definition => {
                if (previousDefinition !== definition) {
                    let definitionName = definition.substring(0, definition.lastIndexOf("#"));
                    let definitionNumber = parseInt(definition.substring(definition.lastIndexOf("#") + 1));
                    if (this.settings.assembly_definitions.includes(definitionName)) {

                        previousDefinition = definition;

                        if (definitionName !== currentDefinitionName) {
                            gapsInDefinition.forEach(gap => {
                                if (gap.old_number > 0) {
                                    definitionsToRenumber.push(gap);
                                }
                            });
                            gapsInDefinition = [];
                            currentDefinitionName = definitionName;
                            expectedNumber = 1;
                        }

                        if (definitionNumber !== expectedNumber) {
                            for (; expectedNumber < definitionNumber; expectedNumber++) {
                                gapsInDefinition.push({
                                    name: definitionName,
                                    old_number: 0,
                                    new_number: expectedNumber,
                                });
                            }
                        }
                        if (gapsInDefinition.length > 0) {
                            for (let i = gapsInDefinition.length - 1; i > 0; i--) {
                                if (gapsInDefinition[i - 1].old_number > gapsInDefinition[i].new_number) {
                                    gapsInDefinition[i].old_number = gapsInDefinition[i - 1].old_number;
                                } else {
                                    gapsInDefinition[i].old_number = 0;
                                }
                            }
                            gapsInDefinition[0].old_number = definitionNumber;
                        }

                        expectedNumber++;

                    }
                }
            });
            gapsInDefinition.forEach(gap => {
                if (gap.old_number > 0) {
                    definitionsToRenumber.push(gap);
                }
            });

            sketchup.renumberDefinitions(definitionsToRenumber);
        },
        getToolDataCallback(sketchUpToolData) {
            this.sketchUpToolData = sketchUpToolData;
            this.fetchData();
        },
        makeComponentsUnique(component) {
            this.state.loading = true;
            sketchup.makeComponentsUnique(component.entity_ids);
        },
        makeComponentsUniqueCallback() {
            sketchup.getComponentInstances();
        },
        renumberAssemblyDefinitions() {
            this.state.loading = true;
            sketchup.getModelDefinitions();
        },
        renumberDefinitionsCallback() {
            sketchup.getComponentInstances();
        },
        selectAll() {
            sketchup.selectAll();
        },
        selectComponent(component) {
            if ('entity_id' in component){
                sketchup.selectComponent(component.entity_id);
            } else {
                sketchup.selectComponents(component.entity_ids);
            }
        },
        selectFilterOptions(choices) {
            choices.forEach(choice => {
                this.filterOptions[choice.name] = choice.value;
            });
            this.filterComponentList();
            this.reloadTableData++;
        },
        toggleComponentCheck(component) {
            let i = this.processedComponentList.findIndex(item => (item.id === component.id));
            if (component.checked === 1) {
                this.processedComponentList[i].checked_style = 'background: #28a745; color: white;';
            } else {
                delete this.processedComponentList[i].checked_style;
            }
            this.processedComponentList[i].checked = component.checked;
            i = this.checkedComponentList.findIndex(item => ((item.definition === component.definition) && (item.flipped === component.flipped)));
            if (i === -1) {
                this.checkedComponentList.push({
                    definition: component.definition,
                    flipped: component.flipped,
                });
            } else {
                this.checkedComponentList.splice(i, 1)
            }
            this.filterComponentList();
            this.reloadTableData++;
        },
        zoomComponent(component) {
            sketchup.renderingOption('InactiveHidden', true);
            sketchup.renderingOption('InstanceHidden', true);
            sketchup.layer(this.settings.assembly_tag, true);
            if ('entity_id' in component){
                sketchup.zoomExtents(component.entity_id);
            } else {
                sketchup.zoomExtents(component.entity_ids[0]);
            }
        },
        zoomReset() {
            sketchup.renderingOption('InactiveHidden', false);
            sketchup.renderingOption('InstanceHidden', false);
            sketchup.layer(this.settings.assembly_tag, false);
            sketchup.zoomExtents();
        },
    }
}
</script>

<style scoped>
</style>